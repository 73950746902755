html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background-color: #e1ebf5 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Open Sans',
    'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fix-scroll-padding {
  padding-bottom: 0 !important;
}

.fix-scroll-padding::after {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 1rem;
}
